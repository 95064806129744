import { render, staticRenderFns } from "./OtherRevenue.vue?vue&type=template&id=3f676975&scoped=true&"
import script from "./OtherRevenue.vue?vue&type=script&lang=js&"
export * from "./OtherRevenue.vue?vue&type=script&lang=js&"
import style0 from "./OtherRevenue.vue?vue&type=style&index=0&id=3f676975&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f676975",
  null
  
)

export default component.exports