<template>
    <div>

    <el-main style=" padding-top: 5px;padding-bottom: initial  ">
        <el-form ref="form" :model="form" label-width="80px">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">其他收入一览</strong>
                            </el-col>

                        </el-row>
                    </div>
                    <el-row>
                        <el-col :lg="4" :sm="12" :md="8">
                            <el-date-picker
                                    style=" margin-top: 8px; width: 220px"
                                    v-model="pickerEnd"
                                    type="daterange"
                                    size="mini"
                                    :picker-options="pickerOptions"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    align="right">
                            </el-date-picker>
                        </el-col>
                        <el-col :lg="5" :sm="12" :md="8">
                            <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export ref="xToolbar2">
                            </vxe-toolbar>
                            <el-button style=" margin-top: 8px; margin-left: 8px" type="primary" round  size="mini">搜索查询</el-button>
                        </el-col>
                    </el-row>
                </el-col>

            </el-row>
            <el-row>
                <el-col :lg="24">
                    <vxe-grid
                            border
                            resizable
                            show-overflow
                            keep-source
                            ref="xGrid"
                            size="mini"
                            height="640"
                            highlight-hover-row
                            highlight-hover-column
                            highlight-current-column
                            show-footer
                            :footer-method="footerMethod"
                            :print-config="{}"
                            :data="tableDataMain"
                            :columns="tableColumn"
                            :mouse-config="{selected: false}"
                            :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                            @edit-closed="editClosedEvent"
                            :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">

                        <template #category_edit="{ row}"><!-- 类别 -->
                            <el-select v-model="row.category" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                       clearable>
                                <el-option v-for="item in category" :key="item.id" :label="item.category"
                                           :value="item.category">
                                    <span style="margin:15px;">{{ item.category}}</span>
                                </el-option>
                            </el-select>
                        </template>
                        <template #specification_edit="{ row}"><!-- 实际规格 -->
                            <el-select v-model="row.specification" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                       clearable filterable>
                                <el-option v-for="item in specification" :key="item.id" :label="item.specification"
                                           :value="item.specification">
                                    <span style="margin:15px;">{{ item.specification}}</span>
                                </el-option>
                            </el-select>
                        </template>
                        <template #unit_edit="{ row}"><!-- 单位 -->
                            <el-select v-model="row.unit" size="mini" no-data-text="没有搜索结果!" placeholder="可搜索查询"
                                       clearable>
                                <el-option v-for="item in unit" :key="item.id" :label="item.unit" :value="item.unit">
                                    <span style="margin:15px;">{{ item.unit}}</span>
                                </el-option>
                            </el-select>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>
        </el-form>
    </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'

    export default {

        data() {
            return {
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   },  {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth()+1;
                            if(month<10){
                                month = '0'+month;
                           }
                            // 本月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            picker.$emit('pick', [startDate, now]);
                       }
                   },{
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if(month==0){
                                month = 12;
                                year = year-1;
                           }
                            if(month<10){
                                month = '0'+month;
                           }
                            var myDate = new Date(year,month,0);
                            // 上个月第一天
                            var startDate = new Date(year+'-'+month+'-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year+'-'+month+'-'+myDate.getDate()+' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                pickerStart: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
                pickerEnd: '',
                footerData: [
                    ['合计', '', '', '', '', '', '220', '', '400', '']
                ],
                tableColumn: [
                    {type: 'seq', title: '科目编码', width: 140},
                    {
                        field:'category',
                        width: 150,
                        title: '科目名称',
                        slots: {edit: 'category_edit'},
                        editRender: {autofocus: 'input.el-input__inner'}
                   },
                    {
                        field:'specification',
                        width: 150,
                        title: '本月发生额',
                        slots: {edit: 'specification_pulldown_edit'},
                        editRender: {autofocus: 'input.vxe-input--inner'}
                   },{
                        width: 150,
                        field:'actual_thickness',
                        title: '累计金额',
                        editRender: {name: 'ElInputNumber', props: {controls: false}}
                   }
                ],
                tableDataMain: [
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},
                    {category:'',specification:'',actualThickness:'',unit:'',count:'',quotation:'',price:''},

                ],
                unit: [
                    {id: 1, unit: '吨'},
                    {id: 2, unit: '支'}
                ],
                category: [
                    {id: 1, category: '方管'},
                    {id: 2, category: '矩管'}
                ],
                specification: [
                    {id: 1, specification: '60*40*4*6米'},
                    {id: 2, specification: '200*100*4*6米'},
                    {id: 3, specification: '200*100米4*12米'},
                    {id: 4, specification: '150*100*4*6米'},
                    {id: 5, specification: '50*25*3*6米'},
                    {id: 6, specification: '50*50*3.5*6米'},
                    {id: 7, specification: '160*60*6*6米'},
                    {id: 8, specification: '200*100*6*12米'},
                    {id: 9, specification: '250*250*12*12米'},
                    {id: 10, specification: '60*60*6*米'},
                ],
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
               },
                activeName: 'first',
                checked: false,
                regionList: [],
                restaurants: [
                    {value: '前端', name: '前端'},
                    {value: '后端', name: '后端'}
                ],
                sexList: [
                    {label: '', value: ''},
                    {label: '男', value: '1'},
                    {label: '女', value: '0'}
                ],

           }
       },
        methods: {
            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            editClosedEvent ({ row, column}) {
                console.log(row);
                console.log(column);
                this.footerData = [['合计', '', '', '', '', '', '', '', '', '']];
                this.updateFooter();
           },
            footerMethod () {
                return this.footerData
           },
            sumNum (list, field) {
                let count = 0
                list.forEach(item => {
                    count = count + Number(item[field])
               })
                //console.log("count合计: " + count);
                return count
           },
            onSubmit() {
                //console.log('submit!');
           },


       },
        created() {
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   };


</script>


<style scoped>
    .vxe-table--render-default .vxe-cell {
        padding-left: initial !important;
        padding-right: initial !important;
   }
</style>
